<template>
  <vl-map
    ref="map"
    v-bind:load-tiles-while-animating="true"
    v-bind:load-tiles-while-interacting="true"
    v-on:mounted="mapMounted"
    v-on:click="mapClicked"
    v-on:moveend="mapMoved"
  >
    <vl-view v-bind="view" v-bind:center.sync="centerCoords" v-bind:zoom.sync="zoomLevel" />

    <vl-layer-tile v-bind:visible="nanoTerra.active">
      <vl-source-wms v-bind="nanoTerra.wms" />
    </vl-layer-tile>

    <vl-layer-vector-tile
      ref="osmLayer"
      v-bind:declutter="true"
      v-bind:visible="openStreetMap.active"
      v-on:mounted="osmMounted"
    >
      <vl-source-vector-tile v-if="openStreetMap.loaded" v-bind="openStreetMap.source" />
    </vl-layer-vector-tile>

    <slot />
  </vl-map>
</template>

<script>
import jQuery from "jquery";
import lodash from "lodash";
import proj4 from "proj4";

import api from "@/core/services/api";

import { Control, FullScreen, ZoomSlider } from "ol/control";
import tileLoadFunction from "./TileLoadFunction.js";
import ButtonControl from "./ButtonControl.js";

import  { applyBackground, applyStyle } from "ol-mapbox-style";

import TileJSON from "ol/source/TileJSON";
import GeoJSON from "ol/format/GeoJSON";
import WKT from "ol/format/WKT";

import { addProjection, get as getProjection, Projection, transform, transformExtent } from "ol/proj";
import { register } from "ol/proj/proj4";

const extent        = [ 20.26, 43.61, 29.77, 48.27 ];
const defaultCenter = [ 23.807373, 44.319315 ]
const defaultZoom   = 17;
const minZoom       = 10;
const maxZoom       = 20;

const wktFormat     = new WKT();
const geoJsonFormat = new GeoJSON();

const pseudo   = getProjection("EPSG:3857");
const wgs84    = getProjection("EPSG:4326");
const stereo70 = new Projection({
  code: "EPSG:31700",
  units: "m",
  axisOrientation: "neu",
});

const projections = { map: pseudo, stereo70, wgs84 };

proj4.defs(
  stereo70.getCode(),
  `+proj=sterea +lat_0=46 +lon_0=25 +k=0.99975 +x_0=500000 +y_0=500000
  +ellps=krass +towgs84=28,-121,-77,0,0,0,0 +units=m +no_defs`
);

register(proj4);
addProjection(stereo70);

function readGeometryFromWkt(wkt) {
  return wktFormat.readGeometry(wkt, { dataProjection: wgs84, featureProjection: pseudo });
}

function writeGeometryToWkt(geometry) {
  // OpenLayers WKT format doesn't support the right-handed (counter-clockwise) option,
  // therefore we have to convert to & from GeoJSON (which does) as a workaround.
  const rightHandObj = geoJsonFormat.writeGeometryObject(geometry, { rightHanded: true });
  const rightHandGeo = geoJsonFormat.readGeometry(rightHandObj, { dataProjection: pseudo, featureProjection: wgs84 });

  return wktFormat.writeGeometry(rightHandGeo);
}

const wkt = {
  readGeometry: readGeometryFromWkt,
  writeGeometry: writeGeometryToWkt,
}

export { projections, wkt };

export default {
  props: {
    center: {
      type: Array,

      default() {
        return transform(defaultCenter, wgs84, pseudo);
      },

      validator(value) {
        return value.length === 2;
      },
    },

    controls: {
      type: Array,

      default() {
        return [];
      },

      validator(value) {
        return value.every(elem => elem instanceof Control);
      },
    },

    zoom: {
      type: Number,

      default: defaultZoom,

      validator(value) {
        return value >= minZoom && value <= maxZoom;
      },
    },
  },

  data() {
    return {
      zoomLevel: this.zoom,
      centerCoords: this.center,

      view: {
        minZoom,
        maxZoom,
        extent: transformExtent(extent, wgs84, pseudo),
        projection: pseudo.getCode(),
      },

      nanoTerra: {
        active: true,

        wms: {
          url: process.env.VUE_APP_MAP_NT_ENDPOINT,
          layers: "craiova_nbarby",
          format: "image/jpeg",
          extParams: { TILED: true },
          projection: pseudo.getCode(),
        },
      },

      openStreetMap: {
        active: false,
        loaded: false,

        source: {
          url: null,
          headers: null,
          attributions: [
            "<a href='https://www.maptiler.com/copyright/' target='_blank'>&copy; MapTiler</a>",
            "<a href='https://www.openstreetmap.org/copyright' target='_blank'>&copy; OpenStreetMap contributors</a>"
          ],
          minZoom: null,
          maxZoom: null,
          extent: null,
          projection: pseudo.getCode(),

          tileLoadFunction: (tile, src) => {
            return tileLoadFunction(tile, src, this.openStreetMap.source.headers);
          },
        },

        style: {
          applied: false,
          json: null,
          url: process.env.VUE_APP_MAP_OSM_ENDPOINT,
        },
      },
    };
  },

  watch: {
    center(center) {
      if (center) {
        this.centerCoords = transform(center, wgs84, pseudo);
      }
    },

    centerCoords(center) {
      this.$emit("update:center", center);
    },

    zoom(zoom) {
      if (zoom) {
        this.zoomLevel = zoom;
      }
    },

    zoomLevel(zoom) {
      this.$emit("update:zoom", zoom);
    },
  },

  mounted() {
    this.loadOpenStreetMap();
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    getOlMap() {
      return this.$refs.map.$map;
    },

    reset() {
      this.zoomLevel    = defaultZoom;
      this.centerCoords = transform(defaultCenter, wgs84, pseudo);
    },

    resize(height) {
      const $map = jQuery(this.$el, ".vl-map");

      $map.css({ height: `${height}px` });
      this.getOlMap().updateSize();
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    osmMounted(){
      this.styleOpenStreetMap();
    },

    async mapMounted(event) {
      const olMap = this.getOlMap();

      olMap.addControl(new FullScreen());
      olMap.addControl(new ZoomSlider());

      olMap.addControl(new ButtonControl({
        callback: this.switchLayers,
        content: "&#127757;",
        classes: "switch-layers",
      }));

      for (const control of this.controls) {
        olMap.addControl(control);
      }

      this.$emit("mounted", event);
    },

    mapClicked(event) {
      this.$emit("click", event);
    },

    mapMoved(event) {
      this.$emit("move", event);
    },

    switchLayers() {
      if (this.nanoTerra.active) {
        this.nanoTerra.active     = false;
        this.openStreetMap.active = true;

        return;
      }

      if (this.openStreetMap.active) {
        this.openStreetMap.active = false;
        this.nanoTerra.active     = true;

        return;
      }
    },

    async loadOpenStreetMap() {
      const settings = await api.settings.getMap();
      const headers  = {
        Authorization: "Basic " + window.btoa(`${settings.auth.userName}:${settings.auth.password}`),
      };

      const styleResponse = await fetch(this.openStreetMap.style.url, { headers });
      const styleJson     = await styleResponse.json();

      const allSources  = lodash.map(styleJson.layers, "source");
      const firstSource = lodash.find(allSources, lodash.isString);

      const sourceResponse = await fetch(styleJson.sources[firstSource].url, { headers });
      const sourceJson     = await sourceResponse.json();

      const tileJson = new TileJSON({ tileJSON: sourceJson });
      const tileGrid = tileJson.getTileGrid();

      this.openStreetMap.source.url     = sourceJson.tiles[0];
      this.openStreetMap.source.headers = headers;
      this.openStreetMap.source.minZoom = sourceJson.minzoom;
      this.openStreetMap.source.maxZoom = sourceJson.maxzoom;
      this.openStreetMap.source.extent  = tileGrid.getExtent();

      this.openStreetMap.style.json = styleJson;

      this.openStreetMap.loaded = true;

      this.styleOpenStreetMap();
    },

    styleOpenStreetMap() {
      if (!this.$refs.osmLayer.$layer) return;
      if (!this.openStreetMap.loaded) return;
      if (this.openStreetMap.style.applied) return;

      this.openStreetMap.style.applied = true;

      applyBackground(this.getOlMap(), this.openStreetMap.style.json);
      applyStyle(this.$refs.osmLayer.$layer, this.openStreetMap.style.json, "openmaptiles");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/_mixins.scss";

::v-deep {
  height: 400px;
  background: white;
  border-radius: $card-border-radius;

  .ol-viewport {
    border-radius: $card-border-radius;

    .ol-attribution {
      height: auto;
      padding: 5px 0;
      background: rgba(255, 255, 255, 0.5);

      ul {
        line-height: 1em;
        font-size: 0.9rem;
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;

        a {
          color: rgba(0, 0, 0, .75);

          &:hover {
            color: #6b7c92;
            text-decoration: underline !important;
          }
        }
      }
    }
  }

  .ol-zoom {
    .ol-zoom-out {
      margin-top: 13.65em;
    }
  }

  .ol-zoomslider {
    top: 2.25em;
  }

  .switch-layers {
    top: 2.5em;
    right: .5em;
  }
}
</style>
